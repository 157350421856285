import React from 'react'
import * as ContentStyles from './Content.module.scss'

interface BSAContentProps {
  children: React.ReactNode
}

const BSAContent = ({ children }: BSAContentProps) => {
  return (
    <div className={ContentStyles.Content}>
      <div className={ContentStyles.Wrapper}>{children}</div>
    </div>
  )
}

export default BSAContent
