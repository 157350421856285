import React from 'react'

const BSAFooter = () => {
  return (
    <p style={{ textAlign: 'center' }}>
      &copy; {new Date().getFullYear()} Muddy Stilettos - All Rights Reserved
    </p>
  )
}

export default BSAFooter
