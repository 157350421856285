// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import BSAShortHeader from '../../components/BestSchoolsAwards/Header/ShortHeader'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../lib/utils'
import ResultsTable from '../../components/BestSchoolsAwards/ResultsTable/ResultsTable'

const BSAResults = () => {
  const SchoolContext = useContext(SchoolAwardsContext)
  return (
    <>
      <Helmet>
        <title>
          Results | Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <BSAShortHeader />
      <BSAContent>
        <div
          className={ContentStyles.NarrowContent}
          style={{ textAlign: 'center' }}
        >
          <h1>Results</h1>
          {SchoolContext === SchoolAwardsStates.FINALISTS ||
          SchoolContext === SchoolAwardsStates.WINNERS ? (
            <>
              <ResultsTable />
            </>
          ) : (
            <p>
              <strong>Results will be announced on 27th June 2024</strong>
            </p>
          )}
        </div>
      </BSAContent>
      <BSAFooter />
    </>
  )
}

export default BSAResults
