import React from 'react'
import * as HeaderStyles from '../Header/Header.module.scss'
import Logomark from '../../../components/Logomark/Logomark'

const GoHome = () => {
  return (
    <div className={HeaderStyles.Logomark}>
      <a href="/" title="Return to Muddy Stilettos">
        <Logomark />
      </a>
    </div>
  )
}

export default GoHome
