import React, { Fragment, useContext } from 'react'
import * as ResultsTableStyles from './ResultsTable.module.scss'
import { Cats } from '../../../lib/data/BestSchoolAwards'
import { SchoolAwardsContext, SchoolAwardsStates } from '../../../lib/utils'

const ResultsTable = () => {
  const SchoolContext = useContext(SchoolAwardsContext)
  return (
    <div className={ResultsTableStyles.Wrapper}>
      {SchoolContext === SchoolAwardsStates.FINALISTS && (
        <p>The winners will be announced on 27 June 2024.</p>
      )}
      <div className={ResultsTableStyles.CategoriesList}>
        {Cats.map(c => (
          <Fragment key={c.ID}>
            <div key={c.ID} className={ResultsTableStyles.CategoryBlock}>
              <ul className={ResultsTableStyles.ResultsList}>
                <li className={ResultsTableStyles.CategoryTitle}>{c.title}</li>
                {SchoolContext === SchoolAwardsStates.WINNERS && (
                  <>
                    <li
                      className={`${ResultsTableStyles.CategorySubtitle} ${ResultsTableStyles.Winner}`}
                    >
                      Winner
                      {c.tags.length > 1 && ` (Prep)`}
                    </li>
                    <li className={ResultsTableStyles.Winner}>
                      {c.winner && c.winner}
                    </li>
                  </>
                )}
                <li className={ResultsTableStyles.CategorySubtitle}>
                  Finalists
                  {c.tags.length > 1 && ` (Prep)`}
                </li>
                {c.finalists &&
                  c.finalists.length > 0 &&
                  c.finalists.sort().map(f => {
                    if (
                      SchoolContext === SchoolAwardsStates.WINNERS &&
                      c.winner === f
                    )
                      return null

                    return (
                      <li key={f} className={ResultsTableStyles.Finalist}>
                        {f}
                      </li>
                    )
                  })}

                {c.highlyCommended && c.highlyCommended.length > 0 && (
                  <>
                    <li className={ResultsTableStyles.CategorySubtitle}>
                      Highly Commended
                      {c.tags.length > 1 && ` (Prep)`}
                    </li>

                    {c.highlyCommended.length > 0 &&
                      c.highlyCommended.sort().map(f => {
                        return (
                          <li key={f} className={ResultsTableStyles.Finalist}>
                            {f}
                          </li>
                        )
                      })}
                  </>
                )}
              </ul>
            </div>
            {c.tags.length > 1 && (
              <div
                key={c.ID + `_`}
                className={ResultsTableStyles.CategoryBlock}
              >
                <ul className={ResultsTableStyles.ResultsList}>
                  <li className={ResultsTableStyles.CategoryTitle}>
                    {c.title}
                  </li>
                  {SchoolContext === SchoolAwardsStates.WINNERS && (
                    <>
                      <li
                        className={`${ResultsTableStyles.CategorySubtitle} ${ResultsTableStyles.Winner}`}
                      >
                        Winner
                        {c.tags.length > 1 && ` (Senior)`}
                      </li>
                      <li className={ResultsTableStyles.Winner}>
                        {c.secondWinner && c.secondWinner}
                      </li>
                    </>
                  )}
                  <li className={ResultsTableStyles.CategorySubtitle}>
                    Finalists
                    {c.tags.length > 1 && ` (Senior)`}
                  </li>
                  {c.secondFinalists &&
                    c.secondFinalists.length > 0 &&
                    c.secondFinalists.sort().map(f => {
                      if (
                        SchoolContext === SchoolAwardsStates.WINNERS &&
                        c.secondWinner === f
                      )
                        return null
                      return (
                        <li key={f} className={ResultsTableStyles.Finalist}>
                          {f}
                        </li>
                      )
                    })}
                  <li className={ResultsTableStyles.CategorySubtitle}>
                    Highly Commended
                    {c.tags.length > 1 && ` (Senior)`}
                  </li>
                  {c.secondHighlyCommended &&
                    c.secondHighlyCommended.length > 0 &&
                    c.secondHighlyCommended.sort().map(f => {
                      return (
                        <li key={f} className={ResultsTableStyles.Finalist}>
                          {f}
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ResultsTable
