import React from 'react'
import * as HeaderStyles from './Header.module.scss'
import Nav from '../Nav/Nav'
import GoHome from '../GoHome/GoHome'
import { StaticImage } from 'gatsby-plugin-image'

const BSAShortHeader = () => {
  return (
    <div className={HeaderStyles.ShortHeaderWrapper}>
      <GoHome />
      <div className={HeaderStyles.NavWrapper}>
        <Nav theme="dark" site="school" />
      </div>
      <div className={HeaderStyles.ShortHeader}>
        <StaticImage
          src="../../../../static/best-schools-awards/best-schools-awards-blue-sponsor.png"
          alt="Best Schools Awards"
        />
      </div>
    </div>
  )
}

export default BSAShortHeader
