import React from 'react'
import * as LogoMarkStyles from './Logomark.module.scss'

const Logomark = () => {
  return (
    <div className={LogoMarkStyles.Outer}>
      <span className={LogoMarkStyles.Letter}>M</span>
    </div>
  )
}

export default Logomark
